import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import {TenantsClient, TenantsQueryParams} from '../../../infrastracture/api/TenantsClient'
import {TenantTypeIds} from '../../constants/tenantTypeIds'
import customStyles from './customStyles'

interface Props {
  tenantTypeIds?: string[]
  name?: string
  defaultValue?: any
  defaultOptions?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (
    name: any,
    value: any,
    label: any,
    operationalClient: any,
    departments?: any,
    externalProjectId?: any,
    suppliedPurchaseOrder?: any
  ) => void
  id?: string
  removeClients?: boolean
  removeContractors?: boolean
  removeOCContractors?: boolean
  includeAll?: boolean
  clientWOUpdateUserId?: string
  className?: string
  populateManagers?: (managers: any) => void
}

const Tenantselector: React.FC<Props> = (props: any) => {
  const {
    id,
    defaultValue,
    placeholder,
    disabled,
    name,
    removeClients,
    includeAll,
    removeContractors,
    tenantTypeIds,
    removeOCContractors,
    clientWOUpdateUserId,
    populateManagers,
    className
  } = props
  
  const [defaultOptions, setDefaultOptions] = useState<Array<any>>([])
  const [value, setValue] = useState<any>({})

  const defaultId = id || 'tenantId'

  useEffect(() => {
    if(!props.defaultOptions)
    {
      let query = new TenantsQueryParams()
          .WithTenantTypeIds([TenantTypeIds.Client])
          .Sort('lastUsed', 'desc')
          .Paginate(0, 20)
          .WithEnabled(true);

      TenantsClient.List(query).then((response) => {
        let options = response.data.data.map((item: any) => {
          return {
            entity: item,
            value: item.id,
            label: item.name,
          }
        });
        if(includeAll) 
          options = [{value: 'all', label: 'All'}, ...options];
        
        setDefaultOptions(options);
      });
    }else{

      let options = [...props.defaultOptions];
      if(includeAll)
        options = [{value: 'all', label: 'All'}, ...options];
      
      setDefaultOptions(options);
    }
  }, [])

  useEffect(() => {
    if (defaultValue && defaultValue?.label) {
      setValue(defaultValue)
    } else if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const promiseOptions = (inputValue: string) =>
      new Promise<any[]>((resolve) => {

        let query = new TenantsQueryParams()
            .Search(inputValue)
            .Sort('name', 'asc')
            .Paginate(0, 20)
            .WithEnabled(true);
        
        if (clientWOUpdateUserId) query.WithClientWOUpdateUserId(clientWOUpdateUserId)

        if (removeClients) query.WithRemoveClients(true)
        if (removeContractors) query.WithRemoveContractors(true)
        if (removeOCContractors) query.WithRemoveOCContractors(true)
        if (tenantTypeIds && tenantTypeIds.length > 0) query.WithTenantTypeIds(tenantTypeIds)
        
        let data = TenantsClient.List(query).then((response) => {
          let options = response.data.data.map((item: any) => {
            return {
              entity: item,
              value: item.id,
              label: item.name,
            }
          });
          
          if(includeAll) options = [{value: 'all', label: 'All'}, ...options]
          
          return options;
        });

        resolve(data);
      });
  
  const handleChange = async (values: any) => {
    const {onChange} = props
    const {label, value, entity} = values
    setValue(values)
    
    console.log('values')
    console.log(values)
    
    onChange(
      defaultId,
      value,
      label,
        entity.operationalClient,
        entity.departments,
        entity.externalProjectId,
        entity.suppliedPurchaseOrder
    )

    if (value === 'all') return

    let managers = await TenantsClient.GetManagers(value)

    if (populateManagers) {
      if (managers.data && managers.data.length > 0) populateManagers(managers.data)
      else populateManagers([])
    }
  }

  return (
    <AsyncSelect
      className={className}
      onChange={handleChange}
      value={value}
      loadOptions={promiseOptions}
      defaultOptions={defaultOptions}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default Tenantselector
